<template>
  <div
    class="w-full container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700"
  >
    <div class="w-full">
      <div
        class="text-5xl flex justify-start items-center text-left font-dark font-bold"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="mr-2 text-red-700 h-10 w-10"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>
        No se realizó la reserva
      </div>
      <p class="text-2xl text-left md:text-3xl font-light leading-normal">
        Lo sentimos, no pudimos encontrar esta página.
      </p>
      <p class="mb-4 text-left">
        Pero no te preocupes, puedes encontrar lo que buscas volviendo a la
        página principal.
      </p>

      <router-link
        class="group relative flex justify-center py-2 px-8 border border-transparent text-sm font-medium rounded-md text-gray-50 bg-green-500 hover:bg-green-600 focus:outline-none"
        tag="button"
        to="/"
      >
        Volver
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Fail',
  data() {
    return {};
  },
};
</script>

<style scoped>
.error-link-group {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.error-link {
  cursor: pointer;
  color: #e5e7eb;
  text-decoration: underline;
}
.error-link:not(:last-child) {
  margin-right: 1em;
}
</style>
